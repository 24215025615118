import gql from 'graphql-tag'

export const LIST_DEVICE = (templateType) => gql`query LIST_DEVICE ($labelType: String!, $q: FilterInput) {
  devices: listLabel${templateType}Device (labelType: $labelType, q: $q) {
    id type code name
  }
}`

export const CREATE_LABEL_DEVICE = (templateType) => gql`mutation CREATE_LABEL_DEVICE ($labelType: String!, $deviceId: Int!, $labelId: Int!) {
  createLabel${templateType}Device (labelType: $labelType, deviceId: $deviceId, labelId: $labelId) {
    deviceId labelId
  }
}`

export const DESTROY_LABEL_DEVICE = (templateType) => gql`mutation DESTROY_LABEL_DEVICE ($labelType: String!, $deviceId: Int!, $labelId: Int!) {
  destroyLabel${templateType}Device (labelType: $labelType, deviceId: $deviceId, labelId: $labelId) {
    deviceId labelId
  }
}`
