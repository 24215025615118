<template>
  <div class="">

    <div v-if="method ==='edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addDevice(item)">
          </fa>&nbsp;
          {{item.code}} <span v-show="item.name">({{item.name}})</span>
        </li>
      </ul>
    </div>

    Devices:
    <ul>
      <li v-for="device in devices" :key="device.id">
        <fa
          v-if="method ==='edit'"
          icon="trash"
          class="text-danger pointer"
          @click="deleteDevice(device.id)">
        </fa>&nbsp;
        {{device.code}}
        <span v-show="device.name">
          ({{device.name}})
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_DEVICE,
  CREATE_LABEL_DEVICE,
  DESTROY_LABEL_DEVICE
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    labelId: {
      type: Number,
      required: true
    },
    labelType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  computed: {
    devices () {
      return this.formData.devices.filter(v => {
        const name = v.name || ''
        const isCodeFound = v.code.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        const isNameFound = name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        return isCodeFound || isNameFound
      })
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_DEVICE(this.templateType),
        variables: {
          labelType: this.labelType,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.devices
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    addDevice (device) {
      this.$apollo.mutate({
        mutation: CREATE_LABEL_DEVICE(this.templateType),
        variables: {
          labelType: this.labelType,
          deviceId: device.id,
          labelId: this.labelId
        }
      })
      .then(() => {
        this.addDevices(device)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteDevice (deviceId) {
      this.$apollo.mutate({
        mutation: DESTROY_LABEL_DEVICE(this.templateType),
        variables: {
          labelType: this.labelType,
          deviceId,
          labelId: this.labelId
        }
      })
      .then(() => {
        this.removeDevices(deviceId)
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    emitDevices (arr) {
      this.$emit('devices', arr)
    },
    addDevices (device) {
      const index = this.formData.devices.findIndex(v => v.id === device.id)
      if (index === -1) this.formData.devices.unshift(device)
    },
    removeDevices (deviceId) {
      const index = this.formData.devices.findIndex(v => v.id === deviceId)
      this.formData.devices.splice(index, 1)
    },
  },
  watch: {
    search: {
      handler (value) {
        this.getList({
          limit: 10,
          params: {
            search: value
          },
          order: 'code'
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
