import gql from 'graphql-tag'


const listResponse = `
  id code name
  devices {id type code name}
`

export const LIST_LABEL = (templateType) => gql`query LIST_LABEL ($labelType: String!, $q: FilterInput) {
  labels: listLabel${templateType} (labelType: $labelType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id code name description
  devices {id type code name}
`

export const DETAIL_LABEL = (templateType) => gql`query DETAIL_LABEL ($labelType: String!, $labelId: Int!) {
  label: detailLabel${templateType} (labelType: $labelType, labelId: $labelId) {${detailResponse}}
}`

export const CREATE_LABEL = (templateType) => gql`mutation CREATE_LABEL ($labelType: String!, $input: Label${templateType}Input!) {
  createLabel: createLabel${templateType} (labelType: $labelType, input: $input) {${detailResponse}}
}`

export const UPDATE_LABEL = (templateType) => gql`mutation UPDATE_LABEL ($labelType: String!, $labelId: Int!, $input: Label${templateType}Input!) {
  updateLabel: updateLabel${templateType} (labelType: $labelType, labelId: $labelId, input: $input) {${detailResponse}}
}`

export const DESTROY_LABEL = (templateType) => gql`mutation DESTROY_LABEL ($labelType: String!, $labelId: Int!) {
  destroyLabel: destroyLabel${templateType} (labelType: $labelType, labelId: $labelId) {id}
}`

export const LIST_VALIDATION = (templateType) => gql`query LIST_VALIDATION ($labelType: String!) {
  labels: listLabel${templateType}Validation (labelType: $labelType) {
    add {id code name}
    update {id code name}
    delete {id code name}
  }
}`
